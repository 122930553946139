/* .prosemirror-body h1 {
  font-size: 1.2rem;
  font-weight: bold;
}

.prosemirror-body h2 {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 4px 0px;
} */

.prosemirror-body.required [data-question="yes"]:after {
  content: "*";
  margin-left: 2px;
  opacity: 0.5;
}

.prosemirror-body.required [data-question="yes"].empty-node:after {
  content: none;
}

.prosemirror-body [contenteditable] {
  border: none;
  outline: none;
}

/* .prosemirror-body a {
  text-decoration: underline;
}

.prosemirror-body ul,
.prosemirror-body ol {
  margin: 5px 20px;
  list-style: disc;
} */

.ProseMirror .empty-node::before {
  position: absolute;
  /* color: #aaa; */
  opacity: 0.5;
  cursor: text;
}

.ProseMirror .empty-node:hover::before {
  opacity: 0.3;
}

.ProseMirror h1.empty-node::before {
  content: "Type your question here...";
}
